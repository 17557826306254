import { t } from 'i18next'

import { formatResponsePatchCampaign } from '~/modules/retailMedia/dtos/common/campaign/patch'

import { types } from '../constants'

type PatchValues = {
  patchKey?: string
}

const index = {
  pending: `${types.patch}_PENDING`,
  fulfilled: `${types.patch}_FULFILLED`,
  rejected: `${types.patch}_REJECTED`
}

export const patch = {
  [index.pending]: ({ draft, action }: ActionData<any, PatchValues>) => {
    const patchKey = action.values?.patchKey || 'default'

    const oldLoading = draft.industry.campaign.patch.loadingKeys

    draft.industry.campaign.patch.loading = true
    draft.industry.campaign.patch.loadingKeys = [...oldLoading, patchKey]
    draft.industry.campaign.patch.error = null
  },
  [index.fulfilled]: ({
    draft,
    action
  }: ActionData<PatchResponse, PatchValues>) => {
    const patchKey = action.values?.patchKey || 'default'

    const oldLoading = draft.industry.campaign.patch.loadingKeys

    draft.industry.campaign.patch.loading = false
    draft.industry.campaign.patch.loadingKeys = oldLoading.filter(
      item => item !== patchKey
    )
    draft.industry.campaign.patch.error = null

    // Atualizando os dados da campanha aberta
    // Evita um refresh na página
    const currentData = draft.campaign.item.data
    const mergedData = formatResponsePatchCampaign({
      currentData,
      updatedData: action.payload
    })

    draft.campaign.item.data = mergedData
  },
  [index.rejected]: ({ draft, action }: ActionData<any, PatchValues>) => {
    const patchKey = action.values?.patchKey || 'default'

    const oldLoading = draft.industry.campaign.patch.loadingKeys

    draft.industry.campaign.patch.loading = false
    draft.industry.campaign.patch.loadingKeys = oldLoading.filter(
      item => item !== patchKey
    )

    draft.industry.campaign.patch.error =
      !!action.payload && t('rm:actions.industry.campaign.patch.campaignError')
  }
}
