import { bffRetailMedia } from '~/middlewares/axios'

/**
 * GET Valeus
 */

export const getMinCostService = ({ body }: GetMinCostsRequest) =>
  bffRetailMedia.post<GetMinCostsServiceResponse>(`/campaign/min-costs`, body)

/**
 * GET values by campaign
 */

export const getMinCostsByCampaignService = (
  id: BrandedType<string, 'CampaignId'>
) =>
  bffRetailMedia.post<GetMinCostsServiceResponse>(`/campaign/min-costs/${id}`)
